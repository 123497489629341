define("ember-mobiledoc-dom-renderer/components/render-mobiledoc/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lKuLrhXI",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[1,[30,0,[\"renderedMobiledoc\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_componentCards\"]]],null]],null],null,[[[40,[[[1,\"    \"],[46,[30,2,[\"componentName\"]],null,[[\"options\",\"payload\"],[[28,[37,5],[[30,2,[\"options\"]]],null],[28,[37,5],[[30,2,[\"payload\"]]],null]]],null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,2,[\"destinationElement\"]]],null]]],[2]],null],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_componentAtoms\"]]],null]],null],null,[[[40,[[[1,\"    \"],[46,[30,3,[\"componentName\"]],null,[[\"options\",\"payload\",\"value\"],[[28,[37,5],[[30,3,[\"options\"]]],null],[28,[37,5],[[30,3,[\"payload\"]]],null],[28,[37,5],[[30,3,[\"value\"]]],null]]],null],[1,\"\\n\"]],[]],\"%cursor:1%\",[28,[37,3],[[30,3,[\"destinationElement\"]]],null]]],[3]],null]],[\"&attrs\",\"card\",\"atom\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\",\"component\",\"readonly\"]]",
    "moduleName": "ember-mobiledoc-dom-renderer/components/render-mobiledoc/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});