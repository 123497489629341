define("ember-mobiledoc-dom-renderer/utils/document", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDocument = getDocument;
  // Private Ember API usage. Get the dom implementation used by the current
  // renderer, be it native browser DOM or Fastboot SimpleDOM
  function getDocument(context) {
    let documentService = (0, _application.getOwner)(context).lookup('service:-document');
    if (!documentService) {
      throw new Error('ember-mobiledoc-dom-renderer could not get DOM');
    }
    return documentService;
  }
});