define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderer-factory", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-2", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-3", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type"], function (_exports, _, _2, _renderType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * runtime DOM renderer
   * renders a mobiledoc to DOM
   *
   * input: mobiledoc
   * output: DOM
   */

  function validateCards(cards) {
    if (!Array.isArray(cards)) {
      throw new Error('`cards` must be passed as an array');
    }
    for (let i = 0; i < cards.length; i++) {
      let card = cards[i];
      if (card.type !== _renderType.default) {
        throw new Error(`Card "${card.name}" must be of type "${_renderType.default}", was "${card.type}"`);
      }
      if (!card.render) {
        throw new Error(`Card "${card.name}" must define \`render\``);
      }
    }
  }
  function validateAtoms(atoms) {
    if (!Array.isArray(atoms)) {
      throw new Error('`atoms` must be passed as an array');
    }
    for (let i = 0; i < atoms.length; i++) {
      let atom = atoms[i];
      if (atom.type !== _renderType.default) {
        throw new Error(`Atom "${atom.name}" must be type "${_renderType.default}", was "${atom.type}"`);
      }
      if (!atom.render) {
        throw new Error(`Atom "${atom.name}" must define \`render\``);
      }
    }
  }
  class RendererFactory {
    constructor() {
      let {
        cards = [],
        atoms = [],
        cardOptions = {},
        unknownCardHandler,
        unknownAtomHandler,
        markupElementRenderer = {},
        sectionElementRenderer = {},
        dom,
        markupSanitizer = null
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      validateCards(cards);
      validateAtoms(atoms);
      if (!dom) {
        if (typeof window === 'undefined') {
          throw new Error('A `dom` option must be provided to the renderer when running without window.document');
        }
        dom = window.document;
      }
      this.options = {
        cards,
        atoms,
        cardOptions,
        unknownCardHandler,
        unknownAtomHandler,
        markupElementRenderer,
        sectionElementRenderer,
        dom,
        markupSanitizer
      };
    }
    render(mobiledoc) {
      let {
        version
      } = mobiledoc;
      switch (version) {
        case _.MOBILEDOC_VERSION:
        case undefined:
        case null:
          return new _.default(mobiledoc, this.options).render();
        case _2.MOBILEDOC_VERSION_0_3_0:
        case _2.MOBILEDOC_VERSION_0_3_1:
        case _2.MOBILEDOC_VERSION_0_3_2:
          return new _2.default(mobiledoc, this.options).render();
        default:
          throw new Error(`Unexpected Mobiledoc version "${version}"`);
      }
    }
  }
  _exports.default = RendererFactory;
});