define("ember-goods/models/shop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Shop extends _emberData.default.Model {
    // normal class body definition here
  }
  _exports.default = Shop;
});