define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/marker-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MARKUP_MARKER_TYPE = _exports.ATOM_MARKER_TYPE = void 0;
  const MARKUP_MARKER_TYPE = 0;
  _exports.MARKUP_MARKER_TYPE = MARKUP_MARKER_TYPE;
  const ATOM_MARKER_TYPE = 1;
  _exports.ATOM_MARKER_TYPE = ATOM_MARKER_TYPE;
});