define("ember-credit-cards/components/input-credit-card-expiration", ["exports", "@ember/component/text-field", "@ember/object", "ember-credit-cards/utils/formatters", "ember-credit-cards/utils/has-text-selected", "ember-credit-cards/utils/is-digit-keypress"], function (_exports, _textField, _object, _formatters, _hasTextSelected, _isDigitKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function inputValid(value) {
    if (!value) {
      return false;
    }
    value = (value + '').replace(/\D/g, '');
    if (value.length > 6) {
      return false;
    }
    return true;
  }
  function parseInput(value) {
    var parts = (value + '').match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);
    if (!parts) {
      return [];
    }
    var mon = parts[1] || '';
    var year = parts[3] || '';
    if (mon.length === 1 && mon !== '0' && mon !== '1') {
      mon = "0" + mon;
    }
    return [mon, year];
  }
  var _default = _textField.default.extend({
    classNames: ['input-credit-card-expiration'],
    month: null,
    year: null,
    placeholder: '•• / ••',
    autocomplete: 'cc-exp',
    keyPress: function (e) {
      var digit = String.fromCharCode(e.which);
      if (!(0, _isDigitKeypress.default)(e)) {
        return false;
      }
      if ((0, _hasTextSelected.default)(this.element)) {
        return true;
      }
      var value = this.element.value + digit;
      return inputValid(value);
    },
    value: (0, _object.computed)('month', 'year', {
      get() {
        return _formatters.default.formatExpiration(this.get('month'), this.get('year'));
      },
      set(key, value) {
        var parsed = parseInput(value);
        var month = parsed[0];
        var year = parsed[1];
        this.setProperties({
          month: month,
          year: year
        });
        return _formatters.default.formatExpiration(month, year);
      }
    })
  });
  _exports.default = _default;
});