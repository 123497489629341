define("ember-goods/models/payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Payment = (_dec = _emberData.default.attr("number"), _dec2 = _emberData.default.attr("string"), _dec3 = _emberData.default.attr("boolean", {
    defaultValue: true
  }), _dec4 = _emberData.default.attr("string"), _dec5 = _emberData.default.attr("string"), _dec6 = _emberData.default.attr("string"), _dec7 = _emberData.default.attr("string"), _dec8 = _emberData.default.attr("string"), _dec9 = _emberData.default.attr("string"), _dec10 = _emberData.default.attr("string"), _dec11 = _emberData.default.attr("string"), _dec12 = _emberData.default.attr(), _dec13 = _emberData.default.attr(), _dec14 = _emberData.default.attr("string"), _dec15 = _emberData.default.attr("string"), _dec16 = _emberData.default.attr("boolean"), _dec17 = _emberData.default.attr("boolean"), _dec18 = _emberData.default.attr("string"), _dec19 = _emberData.default.attr("string"), _dec20 = _emberData.default.attr("string"), _dec21 = _emberData.default.attr("string"), _dec22 = _emberData.default.attr("string"), _dec23 = _emberData.default.belongsTo("order"), _dec24 = _emberData.default.belongsTo("shop-payment-method"), (_class = class Payment extends _emberData.default.Model {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "amount", _descriptor, this);
      _initializerDefineProperty(this, "token", _descriptor2, this);
      _initializerDefineProperty(this, "capture", _descriptor3, this);
      _initializerDefineProperty(this, "cardNumber", _descriptor4, this);
      _initializerDefineProperty(this, "cardholder", _descriptor5, this);
      _initializerDefineProperty(this, "cardType", _descriptor6, this);
      _initializerDefineProperty(this, "validFrom", _descriptor7, this);
      _initializerDefineProperty(this, "expiryDate", _descriptor8, this);
      _initializerDefineProperty(this, "issueNumber", _descriptor9, this);
      _initializerDefineProperty(this, "cvv", _descriptor10, this);
      _initializerDefineProperty(this, "challengeUrl", _descriptor11, this);
      _initializerDefineProperty(this, "challengeRequest", _descriptor12, this);
      _initializerDefineProperty(this, "challengeResponse", _descriptor13, this);
      _initializerDefineProperty(this, "challengeSuccessUrl", _descriptor14, this);
      _initializerDefineProperty(this, "challengeFailedUrl", _descriptor15, this);
      _initializerDefineProperty(this, "browserJavascriptEnabled", _descriptor16, this);
      _initializerDefineProperty(this, "browserJavaEnabled", _descriptor17, this);
      _initializerDefineProperty(this, "browserColorDepth", _descriptor18, this);
      _initializerDefineProperty(this, "browserScreenHeight", _descriptor19, this);
      _initializerDefineProperty(this, "browserScreenWidth", _descriptor20, this);
      _initializerDefineProperty(this, "browserTimezone", _descriptor21, this);
      _initializerDefineProperty(this, "browserLanguage", _descriptor22, this);
      _initializerDefineProperty(this, "order", _descriptor23, this);
      _initializerDefineProperty(this, "shopPaymentMethod", _descriptor24, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "token", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "capture", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cardNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cardholder", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cardType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "validFrom", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "expiryDate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "issueNumber", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "cvv", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "challengeUrl", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "challengeRequest", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "challengeResponse", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "challengeSuccessUrl", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "challengeFailedUrl", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "browserJavascriptEnabled", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "browserJavaEnabled", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "browserColorDepth", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "browserScreenHeight", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "browserScreenWidth", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "browserTimezone", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "browserLanguage", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "order", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "shopPaymentMethod", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Payment;
});