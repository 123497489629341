define("ember-credit-cards/components/input-credit-card-cvc", ["exports", "@ember/component/text-field", "@ember/object", "ember-credit-cards/utils/has-text-selected", "ember-credit-cards/utils/is-digit-keypress", "ember-credit-cards/utils/is-whitelist-keypress"], function (_exports, _textField, _object, _hasTextSelected, _isDigitKeypress, _isWhitelistKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _textField.default.extend({
    classNames: ['input-credit-card-cvc'],
    autocomplete: 'off',
    placeholder: '•••',
    label: 'Expiration',
    keyPress: function (e) {
      var digit = String.fromCharCode(e.which);
      if (!(0, _isDigitKeypress.default)(e)) {
        return false;
      }
      if ((0, _hasTextSelected.default)(this.element)) {
        return true;
      }
      var value = this.element.value + digit;
      return (0, _isWhitelistKeypress.default)(e) || value.length <= 4;
    },
    value: (0, _object.computed)('cvc', {
      get() {
        return this.get('cvc');
      },
      set(propertyName, newValue) {
        var number = newValue.replace(/\D/g, '').slice(0, 4);
        this.set('cvc', newValue);
        return number;
      }
    })
  });
  _exports.default = _default;
});