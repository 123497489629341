define("ember-goods/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "ember-get-config", "@ember/utils", "@ember/debug"], function (_exports, _oauth2PasswordGrant, _emberGetConfig, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //@ts-ignore
  //@ts-ignore
  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: `${_emberGetConfig.default.APP.goods.host}/tokens`,
    authenticate(identification, password) {
      let scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      if ((0, _utils.isEmpty)(_emberGetConfig.default.APP.goods.apiKey)) {
        (false && !(false) && (0, _debug.assert)("Goods apiKey must be added to the environment config"));
      }
      if ((0, _utils.isEmpty)(_emberGetConfig.default.APP.goods.spaceId)) {
        (false && !(false) && (0, _debug.assert)("Goods spaceId must be added to the environment config"));
      }
      headers = {
        Authorization: "Bearer " + _emberGetConfig.default.APP.goods.apiKey,
        "Space-ID": _emberGetConfig.default.APP.goods.spaceId
      };
      return this._super(identification, password, scope, headers);
    }
  });
  _exports.default = _default;
});