define("ember-credit-cards/utils/is-digit-keypress", ["exports", "ember-credit-cards/utils/is-whitelist-keypress"], function (_exports, _isWhitelistKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isDigitKeypress;
  function isDigitKeypress(e) {
    var keyCode = e.keyCode || e.which;
    var digit = String.fromCharCode(keyCode);
    if (/^\d+$/.test(digit) || (0, _isWhitelistKeypress.default)(e)) {
      return true;
    }
    return false;
  }
});