define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5svlTBnt",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"publicAPI\"]],[28,[37,2],null,[[\"Nav\",\"Days\"],[[50,[30,0,[\"navComponent\"]],0,null,[[\"calendar\"],[[28,[37,4],[[30,0,[\"publicAPI\"]]],null]]]],[50,[30,0,[\"daysComponent\"]],0,null,[[\"calendar\"],[[28,[37,4],[[30,0,[\"publicAPI\"]]],null]]]]]]]],null]],[[[41,[30,1],[[[44,[[50,[28,[37,6],[[28,[37,7],[[30,0,[\"tagWithDefault\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tagWithDefault\"]]]]]],[[[1,\"    \"],[8,[30,2],[[24,0,\"ember-power-calendar\"],[17,3],[16,1,[30,1,[\"uniqueId\"]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,1,[\"Nav\"]],null,null,null],[1,\"\\n        \"],[8,[30,1,[\"Days\"]],null,null,null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]]]],[]],null]],[1]]]],[\"calendar\",\"Tag\",\"&attrs\",\"&default\"],false,[\"let\",\"assign\",\"hash\",\"component\",\"readonly\",\"if\",\"ensure-safe-component\",\"-element\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});