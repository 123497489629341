define('ember-social/components/social-widget', ['exports', 'ember-social/templates/components/social-widget'], function (exports, _socialWidget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['social-widget'],
    layout: _socialWidget.default,

    url: null,

    like: true,

    share: true,

    facebook: true,

    twitter: true,

    linkedin: true,

    email: true,

    emailLinkText: 'Share via email',

    emailBody: 'Hope you enjoy it!',

    emailSubject: 'Check Out This Link',

    facebookLike: Ember.computed.and('like', 'facebook'),

    facebookShare: Ember.computed.and('share', 'facebook'),

    twitterShare: Ember.computed.and('share', 'twitter'),

    linkedinShare: Ember.computed.and('share', 'linkedin'),

    emailShare: Ember.computed.and('share', 'email', 'url')
  });
});