define('ember-metrics/metrics-adapters/google-tag-manager', ['exports', '@ember/string', 'ember-metrics/utils/object-transforms', 'ember-metrics/utils/remove-from-dom', 'ember-metrics/metrics-adapters/base'], function (exports, _string, _objectTransforms, _removeFromDom, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    compact
  } = _objectTransforms.default;

  exports.default = _base.default.extend({
    dataLayer: 'dataLayer',

    toStringExtension() {
      return 'GoogleTagManager';
    },

    init() {
      const config = Ember.get(this, 'config');
      const { id, envParams } = config;
      const dataLayer = Ember.getWithDefault(config, 'dataLayer', 'dataLayer');
      const envParamsString = envParams ? `&${envParams}` : '';

      (false && !(id) && Ember.assert(`[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`, id));


      Ember.set(this, 'dataLayer', dataLayer);

      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + envParamsString;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', Ember.get(this, 'dataLayer'), id);
    },

    trackEvent(options = {}) {
      const compactedOptions = compact(options);
      const dataLayer = Ember.get(this, 'dataLayer');
      const gtmEvent = { 'event': compactedOptions['event'] };
      const eventKeys = ['category', 'action', 'label', 'value'];

      delete compactedOptions['event'];

      for (let key in compactedOptions) {
        if (eventKeys.includes(key)) {
          const capitalizedKey = (0, _string.capitalize)(key);
          gtmEvent[`event${capitalizedKey}`] = compactedOptions[key];
        } else {
          gtmEvent[key] = compactedOptions[key];
        }
      }

      window[dataLayer].push(gtmEvent);

      return gtmEvent;
    },

    trackPage(options = {}) {
      const compactedOptions = compact(options);
      const dataLayer = Ember.get(this, 'dataLayer');
      const sendEvent = {
        event: compactedOptions['event'] || 'pageview'
      };

      const pageEvent = Ember.assign(sendEvent, compactedOptions);

      window[dataLayer].push(pageEvent);

      return pageEvent;
    },

    willDestroy() {
      (0, _removeFromDom.default)('script[src*="gtm.js"]');

      delete window.dataLayer;
    }
  });
});