define("ember-goods/instance-initializers/goods", ["exports", "ember-get-config", "@ember/utils"], function (_exports, _emberGetConfig, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  //@ts-ignore

  function initialize(appInstance) {
    const applicationRoute = appInstance.lookup("route:application");
    const session = appInstance.lookup("service:session");
    let authenticatedRoute = _emberGetConfig.default.APP.goods.signedInRoute;
    let invalidatedRoute = _emberGetConfig.default.APP.goods.signedOutRoute;
    if ((0, _utils.isPresent)(authenticatedRoute)) {
      session.on("authenticationSucceeded", function () {
        applicationRoute.transitionTo(authenticatedRoute);
      });
    }
    if ((0, _utils.isPresent)(invalidatedRoute)) {
      session.on("invalidationSucceeded", function () {
        applicationRoute.transitionTo(invalidatedRoute);
      });
    }
  }
  var _default = {
    initialize,
    name: "session-events",
    after: "ember-simple-auth"
  };
  _exports.default = _default;
});