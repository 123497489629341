define("accounting/helpers/format-number", ["exports", "accounting/format-number", "ember"], function (_exports, _formatNumber, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ember.default.Helper.helper(function (params, options) {
    var value = params[0];
    return (0, _formatNumber.default)(value, options);
  });
  _exports.default = _default;
});