define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTextNode = createTextNode;
  _exports.normalizeTagName = normalizeTagName;
  const NBSP = '\u00A0';
  const EMSP = '\u2003';
  function prepareText(text) {
    return text.replace(/  /g, ' ' + NBSP).replace(/\t/g, EMSP);
  }
  function createTextNode(dom, text) {
    return dom.createTextNode(prepareText(text));
  }
  function normalizeTagName(tagName) {
    return tagName.toLowerCase();
  }
});