define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-utils", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/tag-names", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/sanitization-utils"], function (_exports, _tagNames, _sanitizationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALID_ATTRIBUTES = void 0;
  _exports.defaultMarkupElementRenderer = defaultMarkupElementRenderer;
  _exports.defaultSectionElementRenderer = defaultSectionElementRenderer;
  const VALID_ATTRIBUTES = ['data-md-text-align'];
  _exports.VALID_ATTRIBUTES = VALID_ATTRIBUTES;
  function _isValidAttribute(attr) {
    return VALID_ATTRIBUTES.indexOf(attr) !== -1;
  }
  function handleMarkupSectionAttribute(element, attributeKey, attributeValue) {
    if (!_isValidAttribute(attributeKey)) {
      throw new Error(`Cannot use attribute: ${attributeKey}`);
    }
    element.setAttribute(attributeKey, attributeValue);
  }
  function defaultSectionElementRenderer(tagName, dom) {
    let attrsObj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let element;
    if ((0, _tagNames.isMarkupSectionElementName)(tagName)) {
      element = dom.createElement(tagName);
      Object.keys(attrsObj).forEach(k => {
        handleMarkupSectionAttribute(element, k, attrsObj[k]);
      });
    } else {
      element = dom.createElement('div');
      element.setAttribute('class', tagName);
    }
    return element;
  }
  function sanitizeAttribute(tagName, attrName, attrValue) {
    if (tagName === 'a' && attrName === 'href') {
      return (0, _sanitizationUtils.sanitizeHref)(attrValue);
    } else {
      return attrValue;
    }
  }
  function defaultMarkupElementRenderer(tagName, dom, attrsObj) {
    let element = dom.createElement(tagName);
    Object.keys(attrsObj).forEach(attrName => {
      let attrValue = attrsObj[attrName];
      attrValue = sanitizeAttribute(tagName, attrName, attrValue);
      element.setAttribute(attrName, attrValue);
    });
    return element;
  }
});