define("ember-goods/services/goods", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils", "rsvp"], function (_exports, _service, _object, _computed, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Goods = (_dec = (0, _computed.alias)("basket.basketItems"), (_class = class Goods extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "basketItems", _descriptor2, this);
    }
    async createBasket() {
      return this.store.createRecord("basket").save();
    }
    async getBasket(basketId) {
      return await this.store.find("basket", basketId);
    }
    createBasketItem(basketItems, sku, quantity) {
      let metadata = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let isHidden = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      const store = this.store;
      let basketItem = store.createRecord("basketItem", {
        basket: null,
        quantity: quantity,
        sku: sku,
        price: (0, _object.get)(sku, "price"),
        metadata: metadata,
        isHidden: isHidden
      });
      if ((0, _utils.isNone)(basketItems) === false) {
        //@ts-ignore
        basketItems.pushObject(basketItem);
      }
      return basketItem;
    }
    destroyBasketItem(basketItems, targetBasketItem) {
      return this.destroyBasketItems(basketItems, [targetBasketItem]);
    }
    async destroyBasketItems(basketItems, targetBasketItems) {
      if ((0, _utils.isNone)(basketItems) === false) {
        //@ts-ignore
        basketItems.removeObjects(targetBasketItems);
      }

      //@ts-ignore
      let baskets = basketItems.mapBy("basket.content").uniq();

      //@ts-ignore
      await _rsvp.default.all(targetBasketItems.invoke("destroyRecord"));
      await _rsvp.default.all(baskets.invoke("reload"));
    }
    async saveBasketItem(basketItem) {
      return basketItem.save();
    }
    setBasketItemQuantity(basketItem, quantity) {
      (0, _object.set)(basketItem, "quantity", quantity);
    }
    async addToBasket(basketItems, basket) {
      let unsavedBasketItems = basketItems
      //@ts-ignore
      .filterBy("isNew").filterBy("isSaving", false);
      unsavedBasketItems.setEach("basket", basket);
      await unsavedBasketItems.reduce(function (previous, basketItem) {
        return previous.then(basketItem.save.bind(basketItem));
      }, _rsvp.default.resolve());
      await basket.reload();
    }
    async createOrder(order) {
      return order.save();
    }
    createPayment(order) {
      const store = this.store;
      //@ts-ignore
      const orderPaymentMethod = (0, _object.get)(order, "orderPaymentMethods.firstObject");
      return store.createRecord("payment", {
        amount: (0, _object.get)(orderPaymentMethod, "maxPayableAmount"),
        order: order,
        shopPaymentMethod: (0, _object.get)(orderPaymentMethod, "shopPaymentMethod"),
        token: ""
      });
    }
    fieldsToHash(fields) {
      return fields.reduce((fieldHash, field) => {
        fieldHash[(0, _object.get)(field, "slug")] = (0, _object.get)(field, "values");
        return fieldHash;
      }, []);
    }
    getFieldValue(record, reference) {
      let field = (0, _object.get)(record, "skuFields").find(field => (0, _object.get)(field, "slug") === reference);
      if ((0, _utils.isEmpty)(field)) {
        throw new Error(`SKU field with the reference ${reference} not found in record with ID ${record.id}`);
      }
      return field.get("values.firstObject");
    }
    attributesToHash(attributes) {
      return attributes.reduce((attributeHash, attribute) => {
        //@ts-ignore
        attributeHash[(0, _object.get)(attribute, "slug")] = (0, _object.get)(attribute, "values");
        return attributeHash;
      }, []);
    }
    getAttributeValue(attributes, identifier) {
      let attribute = attributes.find(attribute => (0, _object.get)(attribute, "slug") === identifier);
      if ((0, _utils.isEmpty)(attribute)) {
        throw new Error(`Attribute with the reference ${identifier} not found in attribute list`);
      }
      return (0, _object.get)(attribute, "values");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basketItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Goods;
});