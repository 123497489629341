define("ember-router-scroll/index", ["exports", "@ember/routing/router", "@ember/object", "@ember/service", "@ember/application", "@ember/runloop", "ember-app-scheduler"], function (_exports, _router, _object, _service, _application, _runloop, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ATTEMPTS = 0;
  const MAX_ATTEMPTS = 100; // rAF runs every 16ms ideally, so 60x a second

  let requestId;
  let callbackRequestId;

  /**
   * By default, we start checking to see if the document height is >= the last known `y` position
   * we want to scroll to.  This is important for content heavy pages that might try to scrollTo
   * before the content has painted
   *
   * @method tryScrollRecursively
   * @param {Function} fn
   * @param {Object} scrollHash
   * @param {Element} [element]
   * @void
   */
  function tryScrollRecursively(fn, scrollHash, element) {
    let documentHeight;
    // read DOM outside of rAF
    if (element) {
      documentHeight = Math.max(element.scrollHeight, element.offsetHeight, element.clientHeight);
    } else {
      const body = document.body;
      const html = document.documentElement;
      documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }
    callbackRequestId = window.requestAnimationFrame(() => {
      // write DOM (scrollTo causes reflow)
      if (documentHeight >= scrollHash.y || ATTEMPTS >= MAX_ATTEMPTS) {
        ATTEMPTS = 0;
        fn.call(null, scrollHash.x, scrollHash.y);
      } else {
        ATTEMPTS++;
        tryScrollRecursively(fn, scrollHash, element);
      }
    });
  }

  // to prevent scheduleOnce calling multiple times, give it the same ref to this function
  const CALLBACK = function (transition) {
    this.updateScrollPosition(transition);
  };
  let EmberRouterScroll = (_dec = (0, _service.inject)('router-scroll'), (_class = class EmberRouterScroll extends _router.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "service", _descriptor, this);
    }
    get isFastBoot() {
      const fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }
    init() {
      super.init(...arguments);
      (0, _emberAppScheduler.setupRouter)(this);
      this.on('routeWillChange', () => {
        this._routeWillChange();
      });
      this.on('routeDidChange', transition => {
        this._routeDidChange(transition);
      });
    }
    willDestroy() {
      (0, _emberAppScheduler.reset)();
      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }
      if (callbackRequestId) {
        window.cancelAnimationFrame(callbackRequestId);
      }
      super.willDestroy(...arguments);
    }

    /**
     * Updates the scroll position
     * it will be a single transition
     * @method updateScrollPosition
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     */
    updateScrollPosition(transition) {
      const url = (0, _object.get)(this, 'currentURL');
      const hashElement = url ? document.getElementById(url.split('#').pop()) : null;
      if ((0, _object.get)(this, 'service.isFirstLoad')) {
        (0, _object.get)(this, 'service').unsetFirstLoad();
      }
      let scrollPosition;
      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = {
          x: hashElement.offsetLeft,
          y: hashElement.offsetTop
        };
      } else {
        scrollPosition = (0, _object.get)(this, 'service.position');
      }
      let preserveScrollPosition = ((0, _object.get)(transition, 'router.currentRouteInfos') || []).some(routeInfo => (0, _object.get)(routeInfo, 'route.controller.preserveScrollPosition'));

      // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.
      if (!preserveScrollPosition) {
        preserveScrollPosition = (0, _object.get)(this, 'service.preserveScrollPosition');
      }
      if (!preserveScrollPosition) {
        const scrollElement = (0, _object.get)(this, 'service.scrollElement');
        const targetElement = (0, _object.get)(this, 'service.targetElement');
        if (targetElement || 'window' === scrollElement) {
          tryScrollRecursively(window.scrollTo, scrollPosition);
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));
          if (element) {
            let fn = (x, y) => {
              element.scrollLeft = x;
              element.scrollTop = y;
            };
            tryScrollRecursively(fn, scrollPosition, element);
          }
        }
      }
      this.trigger('didScroll', transition);
    }
    _routeWillChange() {
      if ((0, _object.get)(this, 'isFastBoot')) {
        return;
      }
      (0, _object.get)(this, 'service').update();
    }
    _routeDidChange(transition) {
      if ((0, _object.get)(this, 'isFastBoot')) {
        return;
      }
      const scrollWhenIdle = (0, _object.get)(this, 'service.scrollWhenIdle');
      const scrollWhenAfterRender = (0, _object.get)(this, 'service.scrollWhenAfterRender');
      if (!scrollWhenIdle && !scrollWhenAfterRender) {
        // out of the option, this happens on the tightest schedule
        (0, _runloop.scheduleOnce)('render', this, CALLBACK, transition);
      } else if (scrollWhenAfterRender && !scrollWhenIdle) {
        // out of the option, this happens on the second tightest schedule
        (0, _runloop.scheduleOnce)('afterRender', this, CALLBACK, transition);
      } else {
        (0, _emberAppScheduler.whenRouteIdle)().then(() => {
          this.updateScrollPosition(transition);
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFastBoot", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype)), _class));
  var _default = EmberRouterScroll;
  _exports.default = _default;
});