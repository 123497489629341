define("ember-goods/initializers/goods", ["exports", "ember-goods/adapters/goods", "ember-goods/serializers/goods", "ember-goods/services/goods"], function (_exports, _goods, _goods2, _goods3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register("adapter:application", _goods.default);
    application.register("serializer:application", _goods2.default);
    application.register("service:goods", _goods3.default);
  }
  var _default = {
    name: "goods",
    initialize
  };
  _exports.default = _default;
});