define("ember-modifier/-private/class/modifier", ["exports", "@ember/application", "@ember/modifier", "ember-modifier/-private/class/modifier-manager", "ember-modifier/-private/utils/symbol"], function (_exports, _application, _modifier, _modifierManager, _symbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DESTROYING = _exports.DESTROYED = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const DESTROYING = (0, _symbol.symbol)('destroying');
  _exports.DESTROYING = DESTROYING;
  const DESTROYED = (0, _symbol.symbol)('destroyed');
  _exports.DESTROYED = DESTROYED;
  class ClassBasedModifier {
    constructor(owner, args) {
      _defineProperty(this, DESTROYING, false);
      _defineProperty(this, DESTROYED, false);
      (0, _application.setOwner)(this, owner);
      this.element = null;
      this.args = args;
    }
    didReceiveArguments() {}
    didUpdateArguments() {}
    didInstall() {}
    willRemove() {}
    willDestroy() {}
    get isDestroying() {
      return this[DESTROYING];
    }
    get isDestroyed() {
      return this[DESTROYED];
    }
  }
  _exports.default = ClassBasedModifier;
  (0, _modifier.setModifierManager)(owner => new _modifierManager.default(owner), ClassBasedModifier);
});