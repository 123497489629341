define("ember-goods/services/session", ["exports", "ember-simple-auth/services/session", "@ember/service", "@ember/object"], function (_exports, _session, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //@ts-ignore

  class Session extends _session.default.extend({
    store: (0, _service.inject)(),
    user: (0, _object.computed)(function () {
      return (0, _object.get)(this, "store").queryRecord("user", {
        filter: {
          me: "me"
        }
      });
    })
  }) {}
  _exports.default = Session;
});