define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/tag-names", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/section-types", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/dom"], function (_exports, _sectionTypes, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMarkupSectionElementName = isMarkupSectionElementName;
  _exports.isValidMarkerType = isValidMarkerType;
  _exports.isValidSectionTagName = isValidSectionTagName;
  const MARKUP_SECTION_TAG_NAMES = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pull-quote', 'aside'].map(_dom.normalizeTagName);
  const MARKUP_SECTION_ELEMENT_NAMES = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'aside'].map(_dom.normalizeTagName);
  const LIST_SECTION_TAG_NAMES = ['ul', 'ol'].map(_dom.normalizeTagName);
  const MARKUP_TYPES = ['b', 'i', 'strong', 'em', 'a', 'u', 'sub', 'sup', 's', 'code'].map(_dom.normalizeTagName);
  function contains(array, item) {
    return array.indexOf(item) !== -1;
  }
  function isValidSectionTagName(tagName, sectionType) {
    tagName = (0, _dom.normalizeTagName)(tagName);
    switch (sectionType) {
      case _sectionTypes.MARKUP_SECTION_TYPE:
        return contains(MARKUP_SECTION_TAG_NAMES, tagName);
      case _sectionTypes.LIST_SECTION_TYPE:
        return contains(LIST_SECTION_TAG_NAMES, tagName);
      default:
        throw new Error(`Cannot validate tagName for unknown section type "${sectionType}"`);
    }
  }
  function isMarkupSectionElementName(tagName) {
    tagName = (0, _dom.normalizeTagName)(tagName);
    return contains(MARKUP_SECTION_ELEMENT_NAMES, tagName);
  }
  function isValidMarkerType(type) {
    type = (0, _dom.normalizeTagName)(type);
    return contains(MARKUP_TYPES, type);
  }
});