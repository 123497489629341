define("ember-modifier/-private/class/modifier-manager", ["exports", "ember", "@ember/modifier", "@ember/object", "@ember/runloop", "ember-modifier/-private/class/modifier"], function (_exports, _ember, _modifier, _object, _runloop, _modifier2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class ClassBasedModifierManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.13'));
      this.owner = owner;
    }
    createModifier(factory, args) {
      let Modifier = factory.class;
      return new Modifier(this.owner, args);
    }
    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }
    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      (0, _object.set)(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }
    destroyModifier(instance) {
      instance.willRemove();
      instance.element = null;
      if (instance[_modifier2.DESTROYING]) {
        return;
      }
      let meta = _ember.default.meta(instance);
      instance[_modifier2.DESTROYING] = true;
      if (true) {
        // call this on 3.20+ early because it is using the
        // @ember/destroyable API's `destroy` function (this will
        // ensure that any users _on_ 3.20 that have called
        // `registerDestructor` have their destructors called
        // eslint-disable-next-line ember/new-module-imports
        _ember.default.destroy(instance);
      } else {
        meta.setSourceDestroying();
      }
      (0, _runloop.schedule)('actions', instance, instance.willDestroy);
      (0, _runloop.schedule)('destroy', undefined, scheduleDestroy, instance, meta);
    }
  }
  function scheduleDestroy(modifier, meta) {
    if (modifier[_modifier2.DESTROYED]) {
      return;
    }
    if (!true) {
      // in 3.20+ we call destroy _early_ (because it is actually
      // the @ember/destroyable's `destroy` API)
      // eslint-disable-next-line ember/new-module-imports
      _ember.default.destroy(modifier);
      meta.setSourceDestroyed();
    }
    modifier[_modifier2.DESTROYED] = true;
  }
  var _default = ClassBasedModifierManager;
  _exports.default = _default;
});