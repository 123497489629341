define("ember-mobiledoc-dom-renderer/utils/polyfilled-assign", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let polyfilledAssign = _polyfills.assign || _polyfills.merge;
  var _default = polyfilledAssign;
  _exports.default = _default;
});