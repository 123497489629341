define("ember-app-version/services/app-version", ["exports", "ember", "@ember/service", "@ember/object", "@ember/utils", "fetch", "ember-get-config", "ember-concurrency"], function (_exports, _ember, _service, _object, _utils, _fetch, _emberGetConfig, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/

  const addonConfig = _emberGetConfig.default.APP["app-version"];
  var _default = _service.default.extend({
    version() {
      const {
        APP: {
          version
        }
      } = _emberGetConfig.default;
      return version;
    },
    latestVersion: null,
    isNewer: (0, _object.computed)("latestVersion", function () {
      if (_ember.default.testing === true || addonConfig.isEnabled === false) {
        return false;
      }
      return this.version() !== (0, _object.get)(this, "latestVersion");
    }),
    start() {
      if (_ember.default.testing === true || addonConfig.isEnabled !== true) {
        return;
      }
      (0, _object.set)(this, "latestVersion", this.version());
      (0, _object.get)(this, "checkVersion").perform();
    },
    restart() {
      return this.start();
    },
    stop() {
      (0, _object.get)(this, "checkVersion").cancelAll();
    },
    checkVersion: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(addonConfig.pollDelay);
      while (true) {
        try {
          let response = yield (0, _fetch.default)(addonConfig.url, addonConfig.fetchOptions || {});
          let latestVersion = null;
          if ((0, _utils.isPresent)(addonConfig.versionPath)) {
            let json = yield response.json();
            if (addonConfig.extractConfigFromIndexJSON === true) {
              json = JSON.parse(decodeURIComponent(json.meta[0].content));
            }
            latestVersion = (0, _object.get)(json, addonConfig.versionPath);
          } else {
            latestVersion = yield response.text();
          }
          if ((0, _utils.isPresent)(latestVersion)) {
            (0, _object.set)(this, "latestVersion", latestVersion);
          }
        } catch (e) {
          // continue regardless of error
        }
        yield (0, _emberConcurrency.timeout)(addonConfig.pollInterval);
      }
    }).restartable()
  });
  _exports.default = _default;
});