define("ember-credit-cards/utils/is-whitelist-keypress", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isDigitKeypress;
  const validKeyCodes = (0, _array.A)([9, 8, 46, 27, 13]);
  function isDigitKeypress(e) {
    var keyCode = e.keyCode || e.which;
    return validKeyCodes.includes(keyCode);
  }
});