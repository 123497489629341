define("ember-goods/adapters/goods", ["exports", "@ember-data/adapter/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "@ember/utils", "ember-get-config", "@ember/object", "@ember/service"], function (_exports, _jsonApi, _dataAdapterMixin, _utils, _emberGetConfig, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationAdapter = (_dec = (0, _object.computed)("session.data.authenticated.access_token"), (_class = class ApplicationAdapter extends _jsonApi.default.extend(_dataAdapterMixin.default, {
    pathForType(type) {
      if (this.modelWhitelist.includes(type)) {
        return this._super(...arguments);
      }
      return "content/" + this._super(...arguments);
    }
  }) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "coalesceFindRequests", true);
      _defineProperty(this, "host", _emberGetConfig.default.APP.goods.host);
      _defineProperty(this, "modelWhitelist", ["basket", "basket-item", "basket-rule-validation", "brand", "category", "country", "content-entry-operation", "order", "order-line", "order-payment-method", "payment", "payment-method", "price", "product-category", "product-field", "product-image", "product", "promotion", "shop-member", "shop-password-change", "shop-payment-method", "shop-reset-token", "shop-role", "state", "sku-field", "sku-image", "sku", "user"]);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
    get headers() {
      //Support legacy access_token
      if (!(0, _utils.isNone)(_emberGetConfig.default.APP.goods.access_token)) {
        return {
          Authorization: `Bearer ${_emberGetConfig.default.APP.goods.access_token}`
        };
      }

      //@ts-ignore
      let {
        access_token
      } = (0, _object.get)(this, "session.data.authenticated");
      if ((0, _utils.isNone)(access_token)) {
        access_token = _emberGetConfig.default.APP.goods.apiKey;
      }
      return {
        Authorization: `Bearer ${access_token}`,
        "Space-ID": _emberGetConfig.default.APP.goods.spaceId
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = ApplicationAdapter;
});