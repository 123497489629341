define("ember-credit-cards/components/input-credit-card-zipcode", ["exports", "@ember/component/text-field", "@ember/object", "ember-credit-cards/utils/has-text-selected", "ember-credit-cards/utils/formatters", "ember-credit-cards/utils/is-digit-keypress"], function (_exports, _textField, _object, _hasTextSelected, _formatters, _isDigitKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _textField.default.extend({
    type: 'tel',
    classNames: ['input-credit-card-zipcode'],
    keyPress: function (e) {
      var digit = String.fromCharCode(e.which);
      if (!(0, _isDigitKeypress.default)(e)) {
        return false;
      }
      if ((0, _hasTextSelected.default)(this.element)) {
        return true;
      }
      var value = this.element.value + digit;
      return value.length <= 10;
    },
    value: (0, _object.computed)('zipcode', {
      get() {
        return _formatters.default.formatZipcode(this.get('zipcode'));
      },
      set(key, value) {
        this.set('zipcode', value);
        return _formatters.default.formatZipcode(value);
      }
    })
  });
  _exports.default = _default;
});