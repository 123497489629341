define("ember-route-helpers/helpers/transition", ["exports", "@ember/application", "@ember/component/helper", "@ember/utils", "@ember/helper", "ember-route-helpers/utils/handle-query-params", "ember-route-helpers/utils/mount-point"], function (_exports, _application, _helper, _utils, _helper2, _handleQueryParams, _mountPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class FunctionalHelperManager {
    constructor() {
      this.capabilities = (0, _helper2.capabilities)('3.23', {
        hasValue: true
      });
    }

    // This prevents executing the helper when it's defined, instead it returns a function that will
    // be called when the helper is executed by an action
    createHelper(fn, args) {
      return () => fn(args.positional, args.named);
    }
    getValue(fn) {
      return fn;
    }
  }
  const FUNCTIONAL_HELPER_MANAGER = () => new FunctionalHelperManager();
  class TransitionHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "routerInjection", 'router:main');
      _defineProperty(this, "transitionMethod", 'transitionTo');
    }
    getRouteName(routeName, mountPoint) {
      return (0, _mountPoint.prefixMountPoint)(mountPoint, routeName);
    }
    compute(_params) {
      const fn = maybeEvents => {
        maybeEvents = !Array.isArray(maybeEvents) ? [maybeEvents] : maybeEvents;
        const maybeEvent = maybeEvents[0];
        const owner = (0, _application.getOwner)(this);
        const router = owner.lookup(this.routerInjection);
        const mountPoint = (0, _mountPoint.getMountPoint)(owner);
        let [routeName, ...rest] = _params;
        if (maybeEvent !== undefined && typeof maybeEvent.preventDefault === 'function') {
          maybeEvent.preventDefault();
          maybeEvents.splice(0, 1);
        } else {
          rest = [...rest, ...maybeEvents];
        }
        routeName = this.getRouteName(routeName, mountPoint);
        const params = (0, _handleQueryParams.default)([routeName, ...rest].filter(_utils.isPresent));
        return router[this.transitionMethod](...params);
      };
      (0, _helper2.setHelperManager)(FUNCTIONAL_HELPER_MANAGER, fn);
      return fn;
    }
  }
  _exports.default = TransitionHelper;
});