define("ember-router-scroll/services/router-scroll", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/debug", "@ember/application"], function (_exports, _service, _object, _utils, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let RouterScroll = (_class = class RouterScroll extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "key", void 0);
      _defineProperty(this, "targetElement", void 0);
      _defineProperty(this, "scrollElement", 'window');
      _defineProperty(this, "isFirstLoad", true);
      _defineProperty(this, "preserveScrollPosition", false);
      _defineProperty(this, "scrollWhenIdle", false);
      _defineProperty(this, "scrollWhenAfterRender", false);
    }
    get isFastBoot() {
      const fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }
    init() {
      super.init(...arguments);
      this._loadConfig();
      (0, _object.set)(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      });
    }
    unsetFirstLoad() {
      (0, _object.set)(this, 'isFirstLoad', false);
    }
    update() {
      if ((0, _object.get)(this, 'isFastBoot') || (0, _object.get)(this, 'isFirstLoad')) {
        return;
      }
      const scrollElement = (0, _object.get)(this, 'scrollElement');
      const targetElement = (0, _object.get)(this, 'targetElement');
      const scrollMap = (0, _object.get)(this, 'scrollMap');
      const key = (0, _object.get)(this, 'key');
      let x;
      let y;
      if (targetElement) {
        let element = document.querySelector(targetElement);
        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop;

          // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen
          (0, _object.set)(scrollMap, 'default', {
            x,
            y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        let element = document.getElementById(scrollElement.substring(1));
        if (element) {
          x = element.scrollLeft;
          y = element.scrollTop;
        }
      }

      // only a `key` present after first load
      if (key && 'number' === (0, _utils.typeOf)(x) && 'number' === (0, _utils.typeOf)(y)) {
        (0, _object.set)(scrollMap, key, {
          x,
          y
        });
      }
    }
    _loadConfig() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config && config.routerScroll) {
        const scrollElement = config.routerScroll.scrollElement;
        const targetElement = config.routerScroll.targetElement;
        (false && !(!(scrollElement && targetElement)) && (0, _debug.assert)('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));
        if ('string' === (0, _utils.typeOf)(scrollElement)) {
          (0, _object.set)(this, 'scrollElement', scrollElement);
        }
        if ('string' === (0, _utils.typeOf)(targetElement)) {
          (0, _object.set)(this, 'targetElement', targetElement);
        }
        const {
          scrollWhenIdle = false,
          scrollWhenAfterRender = false
        } = config.routerScroll;
        (0, _object.set)(this, 'scrollWhenIdle', scrollWhenIdle);
        (0, _object.set)(this, 'scrollWhenAfterRender', scrollWhenAfterRender);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "isFastBoot", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype)), _class);
  Object.defineProperty(RouterScroll.prototype, 'position', {
    configurable: true,
    get() {
      const scrollMap = (0, _object.get)(this, 'scrollMap');
      const stateUuid = (0, _object.get)(window, 'history.state.uuid');
      (0, _object.set)(this, 'key', stateUuid);
      const key = (0, _object.get)(this, 'key') || '-1';
      return (0, _object.get)(scrollMap, key) || scrollMap.default;
    }
  });
  var _default = RouterScroll;
  _exports.default = _default;
});